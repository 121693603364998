import { memo, useMemo, useRef } from 'react';
import Image from 'next/image';
import { AnchorButtonMemo } from '../../../buttons/button/button';
import { AnchorTagMemo } from '../../../tag/tag';
import { DetailLinkMemo } from '../../../detail-link/detail-link';
import { ChevronDownIcon } from '../../../svg-icons/chevron-down-icon';
import { InternalLinkMemo } from '../../../internal-link/internal-link';
import { useCard } from '../../../../hooks/use-card';
import { useStore } from '../../../../providers/store-provider';
import { sendMixPanelEvent } from '../../../../utils/mixpanel';
import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_RECT_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../../utils/nav-system';
import { IMAGE_SIZES, IMAGE_OPTIMIZATION_QUALITY } from '../../../../constants.mjs';
import styles from './trending-card.module.css';

export type TrendingCardProps = {
  // Item data.
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  imageUrl: string;
  logoImageUrl?: string;
  category?: { id: string; name: string };
  // Other props.
  rank: number;
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export function TrendingCard({ rank, onActivate, onDeactivate, ...item }: TrendingCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {
    cardId,
    isActive,
    onPointerActivate,
    onPointerDeactivate,
    onFocusActivate,
    onBlurDeactivate,
  } = useCard(ref, {
    onActivate,
    onDeactivate,
  });
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);
  const rankString = useMemo(() => {
    return rank.toString().padStart(2, '0');
  }, [rank]);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${isActive ? styles.isActive : ''}`}
      onPointerDown={onPointerActivate}
      onPointerUp={onPointerActivate}
      onPointerMove={onPointerActivate}
      onPointerEnter={onPointerActivate}
      onPointerLeave={onPointerDeactivate}
      onFocus={onFocusActivate}
      onBlur={onBlurDeactivate}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'x',
      }}
    >
      <div
        className={styles.scrollTarget}
        data-card-arrow-nav-target={cardId}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'x',
        }}
      ></div>
      <div className={styles.title}>
        <span className={styles.titleRank}>{rankString}</span>
        <span className={styles.titleText}>{item.name}</span>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.actions}>
          <AnchorButtonMemo
            className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
            theme="Primary"
            href={item.launchUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              addRecentlyPlayedId(item.id);
              sendMixPanelEvent('CardButtonClicked', {
                ui_location: 'TrendingItemsList',
                id: item.id,
                name: item.name,
                type: item.type,
              });
            }}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-arrow-nav-target="${cardId}"]`,
            }}
          >
            {item.type === 'game' ? 'Play' : 'Launch'}
          </AnchorButtonMemo>
          <DetailLinkMemo
            className={styles.infoAction}
            theme="SecondaryLight"
            itemType={item.type}
            itemId={item.id}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-arrow-nav-target="${cardId}"]`,
            }}
          >
            <ChevronDownIcon />
          </DetailLinkMemo>
        </div>
        <Image
          key={item.imageUrl}
          className={styles.image}
          src={item.imageUrl}
          alt={item.name}
          draggable={false}
          fill
          quality={IMAGE_OPTIMIZATION_QUALITY}
          sizes={IMAGE_SIZES.CardLarge}
        />
        {!!item.logoImageUrl && (
          <div className={styles.logoContainer}>
            <Image
              key={item.logoImageUrl}
              className={styles.logo}
              aria-hidden="true"
              src={item.logoImageUrl}
              alt={item.name}
              draggable={false}
              fill
              quality={IMAGE_OPTIMIZATION_QUALITY}
              sizes={IMAGE_SIZES.CardLarge}
            />
          </div>
        )}
        {!!item.category && (
          <AnchorTagMemo
            className={styles.category}
            name={item.category.name}
            href={`/category/${item.category.id}`}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-arrow-nav-target="${cardId}"]`,
            }}
          />
        )}
        <InternalLinkMemo
          className={styles.imageLink}
          href={item.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            addRecentlyPlayedId(item.id);
            sendMixPanelEvent('CardImageClicked', {
              ui_location: 'TrendingItemsList',
              id: item.id,
              name: item.name,
              type: item.type,
            });
          }}
          tabIndex={-1}
          draggable={false}
        />
        <div className={styles.activeTitle}>{item.name}</div>
      </div>
    </div>
  );
}

export const TrendingCardMemo = memo(TrendingCard);
