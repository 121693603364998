import { memo, Dispatch, SetStateAction } from 'react';
import { SliderCardMemo } from './slider-card';

export type SliderNavItemProps = {
  // Item data.
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  imageUrl: string;
  // Other props.
  slideIndex: number;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

export function SliderNavItem({
  type,
  id,
  name,
  launchUrl,
  imageUrl,
  slideIndex,
  setActiveSlideIndex,
}: SliderNavItemProps) {
  return (
    <SliderCardMemo
      type={type}
      id={id}
      name={name}
      launchUrl={launchUrl}
      imageUrl={imageUrl}
      onActivate={() => {
        setActiveSlideIndex(slideIndex);
      }}
      onDeactivate={() => {
        // Let's reset the active slide index to 0 when the card is deactivated IF
        // the active slide index is still the same as the slide index of this card.
        // Note that we have to be really careful here as the onActivate is often
        // called right before onDeactivate (in the same tick) so we have to do the
        // check in the state callback to ensure we're not resetting the active
        // slide index when it's already been updated.
        setActiveSlideIndex((activeSlideIndex) => {
          if (activeSlideIndex === slideIndex) return 0;
          return activeSlideIndex;
        });
      }}
    />
  );
}

export const SliderNavItemMemo = memo(SliderNavItem);
