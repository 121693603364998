import { memo, useMemo } from 'react';
import Image from 'next/image';
import { BulletArrowDownIcon } from '../svg-icons/bullet-arrow-down';
import { CollectionCardMemo, CollectionCardProps } from './collection-card';
import { ARROW_NAV_CONTAINER_CLASS } from '../../utils/nav-system';
import { IMAGE_SIZES, IMAGE_OPTIMIZATION_QUALITY } from '../../constants.mjs';
import styles from './collection.module.css';

export interface CollectionProps {
  id: string;
  name: string;
  items: (CollectionCardProps & {
    bgLandscapeImageUrl: string;
    bgPortraitImageUrl: string;
  })[];
  flipColumns?: boolean;
}

export function Collection({ id, name, items, flipColumns = false }: CollectionProps) {
  // We only support displaying up to 3 items in a collection.
  const filteredItems = useMemo(() => {
    const itemsCopy = [...items];
    itemsCopy.length = Math.min(3, itemsCopy.length);
    return itemsCopy;
  }, [items]);

  return (
    <div
      className={`${styles.root} ${flipColumns ? styles.flipColumns : ''} ${ARROW_NAV_CONTAINER_CLASS}`}
    >
      <div className={styles.imageColumn}>
        {filteredItems.map(({ bgLandscapeImageUrl, bgPortraitImageUrl }, index) => (
          <div key={index} className={styles.imageContainer}>
            <Image
              key={bgLandscapeImageUrl}
              className={styles.landscapeImage}
              src={bgLandscapeImageUrl}
              alt={name}
              draggable={false}
              fill
              quality={IMAGE_OPTIMIZATION_QUALITY}
              sizes={IMAGE_SIZES.CollectionHeader}
            />
            <Image
              key={bgPortraitImageUrl}
              className={styles.portraitImage}
              src={bgPortraitImageUrl}
              alt={name}
              draggable={false}
              fill
              quality={IMAGE_OPTIMIZATION_QUALITY}
              sizes={IMAGE_SIZES.CollectionHeader}
            />
          </div>
        ))}
        <div className={styles.imageHighlight}></div>
      </div>
      <div className={styles.contentColumn}>
        <div className={styles.header}>
          <div className={styles.headerTopSpacer}></div>
          <p className={styles.headerAnnotation}>
            <BulletArrowDownIcon className={styles.headerAnnotationIcon} />
            <span className={styles.headerAnnotationText}>Collection</span>
          </p>
          <h2 className={styles.headerTitle}>
            <span className={styles.headerTitleText}>{name}</span>
          </h2>
          <div className={styles.headerBottomSpacer}></div>
        </div>
        <div className={styles.cards}>
          {filteredItems.map((item, index) => (
            <CollectionCardMemo
              key={index}
              className={styles.card}
              {...item}
              collectionId={id}
              collectionName={name}
            />
          ))}
          <div className={styles.activeCardIndicator}>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const CollectionMemo = memo(Collection);
