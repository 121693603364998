import { memo } from 'react';
import { TrendingCardMemo, TrendingCardProps } from '../trending-card/trending-card';
import { CardScrollerMemo } from '../../../cards/card-scroller/card-scroller';
import { CardListHeaderMemo } from '../../../cards/card-list-header/card-list-header';
import {
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../../utils/nav-system';
import styles from './trending-card-list.module.css';

export type TrendingCardListItemProps = Pick<
  TrendingCardProps,
  'type' | 'id' | 'name' | 'launchUrl' | 'imageUrl' | 'logoImageUrl' | 'category'
>;

export type TrendingCardListProps = {
  id: string;
  items: TrendingCardListItemProps[];
};

export function TrendingCardList({ id, items }: TrendingCardListProps) {
  const rootId = `trending-card-list-${id}`;
  const headerId = `trending-card-list-header-${id}`;
  const scrollerId = `trending-card-list-scroller-${id}`;

  // No items, no render.
  if (!items.length) return null;

  return (
    <div
      id={rootId}
      className={styles.root}
      role="region"
      aria-labelledby={headerId}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
      }}
    >
      <div
        className={styles.scrollToTarget}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'y',
        }}
      ></div>
      <CardListHeaderMemo
        id={headerId}
        className={styles.header}
        title="Trending Now"
        size="large"
        icon={null}
      />
      <CardScrollerMemo
        className={styles.scroller}
        scrollerId={scrollerId}
        cardSize="large"
        cardOrientation="portrait"
      >
        {items.map((item, index) => (
          <TrendingCardMemo
            key={index}
            type={item.type}
            id={item.id}
            name={item.name}
            launchUrl={item.launchUrl}
            imageUrl={item.imageUrl}
            logoImageUrl={item.logoImageUrl}
            category={item.category}
            rank={index + 1}
          />
        ))}
      </CardScrollerMemo>
    </div>
  );
}

export const TrendingCardListMemo = memo(TrendingCardList);
