import { memo } from 'react';
import { AnchorButtonMemo } from '../../../buttons/button/button';
import { sendMixPanelEvent } from '../../../../utils/mixpanel';
import { ARROW_NAV_TARGET_CLASS } from '../../../../utils/nav-system';
import styles from './games-banner.module.css';

export function GamesBanner() {
  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <img
          className={styles.image}
          src="/games-banner-bg.jpg"
          alt="Games Banner Background"
          loading="lazy"
          decoding="async"
        />
        <div className={styles.bgEdgeFade}></div>
      </div>
      <div className={styles.bgLeft}>
        <img
          className={styles.image}
          src="/games-banner-left.avif"
          alt="Games Banner Left Image"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div className={styles.bgRight}>
        <img
          className={styles.image}
          src="/games-banner-right.avif"
          alt="Games Banner Right Image"
          loading="lazy"
          decoding="async"
        />
      </div>
      <h2 className={styles.title}>
        Can&rsquo;t decide?
        <br />
        Check out all games
      </h2>
      <AnchorButtonMemo
        theme="Primary"
        className={`${ARROW_NAV_TARGET_CLASS}`}
        href="/games"
        onClick={() => {
          sendMixPanelEvent('AllGamesBannerClicked');
        }}
      >
        Explore All Games
      </AnchorButtonMemo>
      <div className={styles.topHighlight}></div>
    </div>
  );
}

export const GamesBannerMemo = memo(GamesBanner);
