import { memo } from 'react';
import Image from 'next/image';
import { InternalLinkMemo } from '../../../internal-link/internal-link';
import { sendMixPanelEvent } from '../../../../utils/mixpanel';
import { ARROW_NAV_TARGET_CLASS } from '../../../../utils/nav-system';
import { IMAGE_SIZES, IMAGE_OPTIMIZATION_QUALITY } from '../../../../constants.mjs';
import styles from './featured-categories.module.css';

export type FeaturedCategoryCardProps = {
  id: string;
  name: string;
  landscapeImageUrl: string;
  portraitImageUrl: string;
  launchUrl: string;
  isWide?: boolean;
};

export function FeaturedCategoryCard({
  id,
  name,
  landscapeImageUrl,
  portraitImageUrl,
  launchUrl,
  isWide = false,
}: FeaturedCategoryCardProps) {
  return (
    <InternalLinkMemo
      className={`${styles.card} ${isWide ? styles.isWideCard : ''} ${ARROW_NAV_TARGET_CLASS}`}
      href={launchUrl}
      onClick={() => {
        sendMixPanelEvent('FeaturedCategoryClicked', { id: id, name: name });
      }}
    >
      <span className={styles.cardContent}>
        <span className={styles.cardImageContainer}>
          <Image
            key={landscapeImageUrl}
            className={styles.cardLandscapeImage}
            src={landscapeImageUrl}
            alt={name}
            draggable={false}
            fill
            quality={IMAGE_OPTIMIZATION_QUALITY}
            sizes={IMAGE_SIZES.FeaturedCategoryLandscape}
          />
          <Image
            key={portraitImageUrl}
            className={styles.cardPortraitImage}
            src={portraitImageUrl}
            alt={name}
            draggable={false}
            fill
            quality={IMAGE_OPTIMIZATION_QUALITY}
            sizes={IMAGE_SIZES.FeaturedCategoryPortrait}
          />
        </span>
        <span className={styles.cardFade}></span>
        <span className={styles.cardTitle}>{name}</span>
        <span className={styles.cardHighlight}></span>
      </span>
    </InternalLinkMemo>
  );
}

export const FeaturedCategoryCardMemo = memo(FeaturedCategoryCard);
