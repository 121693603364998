import { memo, useMemo, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '../../../../hooks/use-isomorphic-layout-effect';
import { AnchorButtonMemo } from '../../../buttons/button/button';
import { FeaturedCategoryCardMemo } from './featured-category-card';
import { ticker } from '../../../../utils/ticker';
import { sendMixPanelEvent } from '../../../../utils/mixpanel';
import { ARROW_NAV_CONTAINER_CLASS, ARROW_NAV_TARGET_CLASS } from '../../../../utils/nav-system';
import styles from './featured-categories.module.css';

export type FeaturedCategoriesProps = {
  categories: {
    id: string;
    name: string;
    landscapeImageUrl: string;
    portraitImageUrl: string;
  }[];
};

export function FeaturedCategories({ categories }: FeaturedCategoriesProps) {
  const ref = useRef<HTMLDivElement>(null);

  // We only support displaying up to 4 items.
  const filteredCategories = useMemo(() => {
    const categoriesCopy = [...categories];
    categoriesCopy.length = Math.min(4, categoriesCopy.length);
    return categoriesCopy;
  }, [categories]);

  // We need to compute the dynamic CSS variables for the cards, for the
  // scale effect. We want to expand the card 16px on the wider axis and the
  // other axis proportionally.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;

    if (!filteredCategories.length) return;

    const container = ref.current;
    if (!container) return;

    const frameListenerId = Symbol();

    const normalCard = container.querySelector(
      `.${styles.card}:not(.${styles.isWideCard})`,
    ) as HTMLElement | null;

    const wideCard = container.querySelector(
      `.${styles.card}.${styles.isWideCard}`,
    ) as HTMLElement | null;

    const computeVariables = () => {
      let scales = [1, 1];

      ticker.once(
        'read',
        () => {
          [normalCard, wideCard].forEach((card, index) => {
            if (!card) return;
            const { width, height } = card.getBoundingClientRect();
            scales[index] = width > height ? (width + 16) / width : (height + 16) / height;
          });
        },
        frameListenerId,
      );

      ticker.once(
        'write',
        () => {
          [normalCard, wideCard].forEach((card, index) => {
            if (!card) return;
            container.style.setProperty(
              `--featured-categories--${index === 0 ? 'normal' : 'wide'}-card-active-scale`,
              `${scales[index]}`,
            );
          });
        },
        frameListenerId,
      );
    };

    // Compute variables on initial render.
    computeVariables();

    // Recompute variables on window resize.
    window.addEventListener('resize', computeVariables);

    return () => {
      window.removeEventListener('resize', computeVariables);
      ticker.off('read', frameListenerId);
      ticker.off('write', frameListenerId);
      container.style.removeProperty('--featured-categories--normal-card-active-scale');
      container.style.removeProperty('--featured-categories--wide-card-active-scale');
    };
  }, [filteredCategories.length]);

  // No items, no render.
  if (!filteredCategories.length) return null;

  return (
    <div ref={ref} className={`${styles.root} ${ARROW_NAV_CONTAINER_CLASS}`}>
      <h2 className={styles.title}>Popular Genres</h2>
      <p className={styles.description}>
        From fast-paced shooters to engaging adventures—dive into a popular genre or explore all our
        games.
      </p>
      <AnchorButtonMemo
        className={`${styles.link} ${ARROW_NAV_TARGET_CLASS}`}
        href="/games"
        onClick={() => {
          sendMixPanelEvent('PopularGenresAllGamesClicked');
        }}
      >
        See all games
      </AnchorButtonMemo>
      <div className={styles.cards}>
        {filteredCategories.map((category, index) => (
          <FeaturedCategoryCardMemo
            key={category.id}
            id={category.id}
            name={category.name}
            landscapeImageUrl={category.landscapeImageUrl}
            portraitImageUrl={category.portraitImageUrl}
            launchUrl={`/category/${category.id}`}
            isWide={index === 1 || index === 2}
          />
        ))}
      </div>
    </div>
  );
}

export const FeaturedCategoriesMemo = memo(FeaturedCategories);
